import React, { useState } from 'react';

function GameCell({ value, onChange, onEnter, hideAnswer, isCorrect, style}) {
  const [shake, setShake] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true)
  const handleIncorrect = () => {
    setShake(true);
    setIsEnabled(true);
    setTimeout(() => {
      setShake(false);
    }, 1000); // Adjust the timeout as needed
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setIsEnabled(false);
      onEnter(e.target.value,handleIncorrect);
    }
  };
  function getImageBackgroundColor(answerType) {
    if (answerType){
      if (answerType === 'artist') {
        return "#F6BB42"
      }
      if (answerType === 'song') {
        return "#8CC152"
      }
    }
    return '#DDDBDD'
  }
  return (
    <div className="cell" style={style}>
      {!isCorrect ? (
        <>
        <div className='loadingContainer' style={{display: `${!isEnabled ? 'flex': 'none'}`}}>
          <div className='loading'></div>
        </div>
        <input
          className={`cell-input ${shake ? 'input-shake' : ''}`}
          type="text"
          value={value}
          onChange={onChange}
          disabled={!isEnabled}
          onKeyUp={handleKeyPress}
          style={{ width: '100%', height: '100%', textAlign: 'center' }}
          inputMode='search'
        />
        </>
      ) : (
        <>
        <div className='cell-answer-image-container'>
          <img
            src={`${value.imageUrl}`}
            alt={`${value.answer}`}
            className='cell-image cell-answer-image' style={{zIndex: 1}} 
            data-html2canvas-ignore={hideAnswer ? '' : null} />
          <span className='cell-image cell-answer-image' style={{backgroundColor: getImageBackgroundColor(value.type)}}></span>
        </div>
        <span className='cell-answer-text' data-html2canvas-ignore={hideAnswer ? '' : null}>{value.answer}</span>
        {value.score && <span className='cell-answer-text'>{value.score}</span>}
        </>
      )}
    </div>
  );
}

export default GameCell;
