// components/GameBoard.js

import React, {useState,useRef, useEffect} from 'react';
import GameCell from './GameCell';

import ConfettiExplosion from 'react-confetti-explosion';
import html2canvas from 'html2canvas';

function GameBoard({ gameData, onCellChange, onCellCheck, answerData, gameDate }) {
  const [showConfetti, setShowConfetti] = useState(false);
  const [hideAnswers, setHideAnswers] = useState(true);
  const [screenshotBlob, setScreenshotBlob] = useState(null);
  const [isScreenshotLoading, setIsScreenshotLoading] = useState(false)
  const ref = useRef(null);
  const numCols = answerData.answers[0].length;

  useEffect(()=>{
    if (shouldShare(answerData) && ref) {
      setIsScreenshotLoading(true)
      takeScreenshot(ref.current, { useCORS: true })
      .then((image) => {
        fetch(image)
        .then(res => res.blob())
        .then(blob => {
          setScreenshotBlob(blob)
          setIsScreenshotLoading(false)
        })
      })
    }
  },[answerData,hideAnswers])
  
  async function takeScreenshot(node, options) {
    const canvas = await html2canvas(node, options);
    const croppedCanvas = document.createElement('canvas');
    const croppedCanvasContext = croppedCanvas.getContext('2d');
    const cropPositionTop = 0;
    const cropPositionLeft = 0;
    const cropWidth = canvas.width;
    const cropHeight = canvas.height;
    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;
    croppedCanvasContext.drawImage(
      canvas,
      cropPositionLeft,
      cropPositionTop);
    const base64Image = croppedCanvas.toDataURL();
    return base64Image;
  }

  function shouldShare(answerData) {
    for (let r = 0; r < answerData.answers.length; r++) {
      for (let c = 0; c < answerData.answers[r].length; c++) {
        if (!answerData.answers[r][c].answer.score) {
          return false;
        }
      }
    }
    return true;
  }

  function onShare() {
    const file = new File([screenshotBlob], `tunegrid-${gameDate}.png`,{ type: screenshotBlob.type })
    const files = [file]
    navigator.share({
      files,
      text: `#tunegrids #${gameDate}\nhttps://tunegrids.com`
    }).catch((e)=>{console.log(e)})
  }

  const handleGameComplete = () => {
    setShowConfetti(true)
  }

  const renderHeaderCells = () => {
    const headerCells = [];
    // Add an score cell at the top-left corner
    const isShareDisabled = !navigator.share || isScreenshotLoading
    headerCells.push(
      <div key={`header-top-left`} className="cell header-cell">
        <span>{`Score: ${answerData.score}`}<span id='max-score'>/400</span></span>
        { shouldShare(answerData) && 
          <>
          <button className={isShareDisabled ? 'button-hover': ''} type="button" disabled={isShareDisabled} onClick={onShare} data-html2canvas-ignore>
            Share
            { showConfetti && <ConfettiExplosion /> }
          </button> 
          <div style={{display: 'flex', flexDirection:'row'}} data-html2canvas-ignore>
            <input type="checkbox" id='hide-answers' name='hide-answers' disabled={!navigator.share} checked={hideAnswers} onChange={(e)=>{setHideAnswers(e.target.checked)}}/>
            <label htmlFor="hide-answers">Hide Answers</label>
          </div>
          </>
        }
      </div>
    );

    // Add column headers
    for (let colIndex = 0; colIndex < numCols; colIndex++) {
      if (gameData.columnArtists[colIndex].name !== '...') {
        headerCells.push(
          <div key={`header-col-${colIndex}`} className="cell header-cell">
            <img
              src={`${gameData.columnArtists[colIndex].imageUrl}`}
              alt={`${gameData.columnArtists[colIndex].name}`}
              className='cell-image'
            />
            <span className='cell-answer-text'>{`${gameData.columnArtists[colIndex].name}`}</span>
          </div>
        );
      } else {
        headerCells.push(
          <div key={`header-col-${colIndex}`} className="cell header-cell">
            <span className='skeleton cell-image' />
            <p className='skeleton skeleton-header-text' />
          </div>
        );
      }
    }

    return headerCells;
  };

  return (
    <div ref={ref} className="game-board">
      {/* Render column headers */}
      <div className="row">{renderHeaderCells()}</div>

      {answerData.answers.map((row, rowIndex) => (
        <div key={`row-${rowIndex}`} className="row">
          {/* Render row header */}
          <div key={`header-row-${rowIndex}`} className="cell header-cell">
            { gameData.rowArtists[rowIndex].name !== '...' ? 
            <>
            <img
              src={`${gameData.rowArtists[rowIndex].imageUrl}`}
              alt={`${gameData.rowArtists[rowIndex].name}`}
              className='cell-image'
            /> 
            <p>{`${gameData.rowArtists[rowIndex].name}`}</p>
            </> 
            :
            <>
            <span className='skeleton cell-image' />
            <p className='skeleton skeleton-header-text' />
            </>
            }
            
          </div>

          {row.map((cell, colIndex) => (
            <GameCell
              key={`cell-${rowIndex}-${colIndex}`}
              value={cell.answer}
              onChange={(e) => onCellChange(rowIndex, colIndex, e.target.value)}
              onEnter={(value,handleIncorrect) => onCellCheck(rowIndex, colIndex, value, handleIncorrect, handleGameComplete)}
              hideAnswer={hideAnswers}
              isCorrect={cell.correct}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default GameBoard;