import React, { useState, useEffect } from 'react';

import GameBoard from './components/GameBoard';
import './App.css';

const ARRAY_SIZE = 2
const LOCAL_STORAGE_VERSION = "4"

function App() {
  const [wasGiveUpPressed,setWasGiveUpPressed] = useState(false);
  const [gameDate, setGameDate] = useState('');
  const [gameData, setGameData] = useState(getInitialGameData());
  const [answerData, setAnswerData] = useState(getInitialAnswerData());
  const [failedAttempts, setFailedAttempts] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        // Make the API call to retrieve initial game data
        const response = await fetch(process.env.REACT_APP_SERVER_URL+'/artists');
        const data = await response.json();
        const fetchedGameDate = data.date
        const fetchedGameData = {
          'maxScore': data.maxScore,
          'columnArtists': data.columnArtists,
          'rowArtists': data.rowArtists
        }
        // Invalidate vestigial local storage data
        if(localStorage.getItem("version") !== LOCAL_STORAGE_VERSION) {
          localStorage.clear();
          localStorage.setItem("version", LOCAL_STORAGE_VERSION);
        }
        // Save data to local storage
        if (!(fetchedGameDate in localStorage)) {
          const localStorageItem = {
            answerData: getInitialAnswerData(),
            gameData: fetchedGameData
          }
          localStorage.setItem(fetchedGameDate, JSON.stringify(localStorageItem));
        }
        // Update the state with the fetched data
        setGameDate(fetchedGameDate)
        setGameData(fetchedGameData);
      } catch (error) {
        console.error('Error fetching initial game data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Check if answer data is in local storage
    if ( gameDate !== '' && gameDate in localStorage ) {
      const localStorageItem = localStorage.getItem(gameDate);
      const persistedData = JSON.parse(localStorageItem);
      setAnswerData(persistedData.answerData);
      setGameData(persistedData.gameData);
    }
  }, [gameDate])

  function getInitialGameData() {
    return {
      rowArtists: [{'imageUrl': null, 'name': '...'}, {'imageUrl': null, 'name': '...'}],
      columnArtists: [{'imageUrl': null, 'name': '...'}, {'imageUrl': null, 'name': '...'}],
    };
  }

  function getInitialAnswerData() {
    const answers = new Array(ARRAY_SIZE);
    for (let r = 0; r < ARRAY_SIZE; r++) {
      answers[r] = new Array(ARRAY_SIZE);
      for (let c = 0; c < ARRAY_SIZE; c++) {
        answers[r][c] = { answer: '', correct: false }
      }
    }
    const answerData = {};
    answerData.answers = answers
    answerData.score = 0
    return answerData
  }

  function handleCellChange(row, col, value) {
    // Handle the logic when the value in a cell changes
    // Update the gameData with the entered song name
    const updatedAnswerData = { ...answerData };
    updatedAnswerData.answers[row][col].answer = value;
    setAnswerData(updatedAnswerData);
  }
  
  function checkIfAnswerUsed(answerData, answer) {
    for (let r = 0; r < ARRAY_SIZE; r++) {
      for (let c = 0; c < ARRAY_SIZE; c++) {
        // if answer has key answer, cell was completed
        if (answerData.answers[r][c].answer.answer === answer.answer) {
          return true
        }
      }
    }
    return false
  }
  const checkCell = async (row, col, value, handleIncorrect, handleGameComplete) => {
    try {
      // Make a request to your server to update the cell data
      const response = await fetch(process.env.REACT_APP_SERVER_URL+'/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          rowArtist: gameData.rowArtists[row].name,
          columnArtist: gameData.columnArtists[col].name,
          songName: value,
          date: gameDate
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        // Handle server error
        console.error('Error updating cell data on the server:', response.statusText);
      }
      // Update score
      const updatedAnswerData = { ...answerData };
      if (data.result && !checkIfAnswerUsed(updatedAnswerData,data.result)) {
        updatedAnswerData.score += data.result.score;
        updatedAnswerData.answers[row][col].correct = true;
        updatedAnswerData.answers[row][col].answer = data.result;

        // Save answers to local storage
        const localStorageItem = {
          'answerData': updatedAnswerData,
          'gameData': gameData
        }
        localStorage.setItem(gameDate, JSON.stringify(localStorageItem));

        // check if game complete
        if (isGameComplete()) {
          handleGameComplete()
        }
      } else {
        handleIncorrect()
        setFailedAttempts(failedAttempts + 1)
        updatedAnswerData.answers[row][col].correct = false;
      }
      setAnswerData(updatedAnswerData);
    } catch (error) {
      // Handle fetch error
      console.error('Error updating cell data:', error);
    }
  }

  const giveUp = async () => {
    setWasGiveUpPressed(true)
    const unansweredPairs = []
    const userAnswers = []
    const pairsIndex = {}
    for (let r = 0; r < ARRAY_SIZE; r++) {
      for (let c = 0; c < ARRAY_SIZE; c++) {
        if (!answerData.answers[r][c].correct) {
          const pair = gameData.rowArtists[r].name + '-' + gameData.columnArtists[c].name;
          unansweredPairs.push(pair)
          pairsIndex[pair] = { row: r, col: c }
        } else {
          userAnswers.push(answerData.answers[r][c].answer.answer)
        }
      }
    }
    try {
      const response = await fetch (process.env.REACT_APP_SERVER_URL+'/giveUp',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "unansweredPairs": unansweredPairs,
          "userAnswers": userAnswers,
          "date": gameDate,
        }),
      })
      const data = await response.json();
      if (!response.ok) {
        // Handle server error
        console.error('Error updating cell data on the server:', response.statusText);
      }

      // Update answers
      const updatedAnswerData = { ...answerData };
      for (let pair in data.result) {
        const r = pairsIndex[pair].row
        const c = pairsIndex[pair].col
        updatedAnswerData.answers[r][c].correct = true
        updatedAnswerData.answers[r][c].answer = data.result[pair]
      }
      // Save answers to local storage
      const localStorageItem = {
        'answerData': updatedAnswerData,
        'gameData': gameData
      }
      localStorage.setItem(gameDate, JSON.stringify(localStorageItem));
      setAnswerData(updatedAnswerData);
    } catch (error) {
      // Handle fetch error
      console.error('Error getting answers for pairs:', error);
    }
  }

  function isGameComplete() {
    for (let r = 0; r < ARRAY_SIZE; r++) {
      for (let c = 0; c < ARRAY_SIZE; c++) {
        if (answerData.answers[r][c].correct !== true) {
          return false;
        }
      }
    }
    return true;
  }

  let dateOptions = Object.keys(localStorage)
  dateOptions.sort()
  dateOptions.reverse()
  dateOptions = dateOptions.filter(k => k !== 'version')

  return (
    <div className="App">
      <div className="gamespace">
        <div className='header'>
          <div className='brand'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#F87060" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" id="logo">
              <path d="M9 18V5l12-2v13"></path>
              <circle cx="6" cy="18" r="3"></circle>
              <circle cx="18" cy="16" r="3"></circle>
            </svg>
            <h1 style={{color: '#F87060'}}>TuneGrids</h1>
          </div>
          <div>
            <label htmlFor="date-select">date: </label>
            <select id="date-select" name="date-select" value={gameDate} onChange={(e) => setGameDate(e.target.value)}>
              {dateOptions.map((date) => (<option key={date} value={date}>{date}</option>))}
            </select>
          </div>
        </div>
        <GameBoard gameData={gameData} onCellChange={handleCellChange} onCellCheck={checkCell} answerData={answerData} gameDate={gameDate}/>
        <button onClick={giveUp} type="button" id="forfeit-button" className={failedAttempts >= 3 && !wasGiveUpPressed && !isGameComplete() ? 'button-hover': ''} disabled={ failedAttempts < 3 || wasGiveUpPressed || isGameComplete() }>Give Up</button>
        <div className='instructions'>
          <h2 style={{textAlign: 'left'}}>How to play</h2>
          <p style={{textAlign: 'left'}}>
            For each cell, name a song that officially features the cell's row artist and column artist or an artist that has collaborated with the cell's row artist and column artist. Click on a cell, type your guess, and press enter to submit your answer.
          </p>
          <p style={{textAlign: 'left'}}>
            You earn more points by entering a song that is lesser known or an artist that has more songs with the header artists. The artist cannot be one of the artists in the headers. An answer can only be used once in the grid.
          </p>
          <p style={{textAlign: 'left'}}>
            The "Give Up" button can be used after three failed attempts. Use this button as a last resort to reveal possible connections and forfeit the game. Only use this option if you're unable to progress further. Complete all four cells to win!
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
